import React, { useState } from "react";
import axios from "axios";
import "../styles/VerifyCertificate.css";

const VerifyCertificate = () => {
    const [certificateHash, setCertificateHash] = useState("");
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleVerify = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `http://127.0.0.1:5000/verify?hash=${certificateHash}`
            );
            setResult(response.data);
        } catch (error) {
            setResult({
                status: "Error",
                message: error.response
                    ? error.response.data.message
                    : "Something went wrong.",
            });
        }
        setLoading(false);
    };

    return (
        <div className="verify-container">
            <h1>Verify Certificate</h1>
            <p>Enter the certificate hash to verify its authenticity.</p>

            <div className="form-group">
                <input
                    type="text"
                    placeholder="Enter Certificate Hash"
                    value={certificateHash}
                    onChange={(e) => setCertificateHash(e.target.value)}
                />
                <button onClick={handleVerify} disabled={loading || !certificateHash}>
                    {loading ? "Verifying..." : "Verify"}
                </button>
            </div>

            {result && (
                <div className={`result ${result.status.toLowerCase()}`}>
                    <h2>Status: {result.status}</h2>
                    {result.status === "Valid" && (
                        <div>
                            <p><strong>Name:</strong> {result.name}</p>
                            <p><strong>DOB:</strong> {result.dob}</p>
                            <p><strong>Exam Date:</strong> {result.exam_date}</p>
                            <p><strong>Valid Until:</strong> {result.valid_until}</p>
                            <p><strong>Certificate Type:</strong> {result.certificate_type}</p>
                        </div>
                    )}
                    <p>{result.message}</p>
                </div>
            )}
        </div>
    );
};

export default VerifyCertificate;
